<template>
    <Toast />
    <div class="p-grid">
        <div class="p-col-12">
            <div class="p-d-flex p-ai-center p-jc-between p-flex-column p-flex-lg-row p-mb-1">
                <h5>Template Event</h5>
                <Button label="Back" icon="pi pi-chevron-left" class="p-mr-2" @click="goToPrevious()" />
            </div>
            <div class="card">
                <TabView @tab-change="getTabChangeSettings">
                    <TabPanel header="Add Event">
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-12 p-md-4">
                                <label for="eventNameId">
                                    Event name
                                    <span class="p-invalid p-error">*</span>
                                </label>
                                <InputText id="eventNameId" class="p-text-capitalize" v-model.trim="evt.eventName"
                                    required="true" maxlength="50" autofocus
                                    :class="{ 'p-invalid': submitted && !evt.eventName }" />
                                <small class="p-invalid p-error" v-if="v$.evt.eventName.$error">{{
                                    v$.evt.eventName.$errors[0].$message
                                }}</small>
                            </div>
                            <div class="p-field p-col-12 p-md-4">
                                <label for="ChannelId">
                                    Channel Name
                                    <span class="p-invalid p-error">*</span>
                                </label>
                                <MultiSelect id="ChannelId" v-model="evt.ChannelName" :options="ChannelIdList"
                                    optionValue="value" optionLabel="label" placeholder="Select" :filter="true"
                                    class="multiselect-custom" appendTo="body"></MultiSelect>
                                <small class="p-invalid p-error" v-if="v$.evt.ChannelName.$error">{{
                                    v$.evt.ChannelName.$errors[0].$message
                                }}</small>
                            </div>
                            <div class="p-field p-col-12 p-md-4">
                                <label>status <span class="p-invalid p-error"> *</span></label>
                                <div class="p-field-radiobutton">
                                    <RadioButton id="status_active" name="option" v-bind:value="1"
                                        v-model="evt.evtStatusType" :checked="evt.evtStatusType == 1" />
                                    <label for="status_active" class="p-mr-2">Active</label>
                                    <RadioButton id="status_Inactive" name="option" v-bind:value="0"
                                        v-model="evt.evtStatusType" :checked="evt.evtStatusType == 0" />
                                    <label for="status_Inactive" class="p-mr-2">Inactive</label>
                                </div>
                            </div>
                            <div class="p-field p-col-12 p-md-4">
                                <label for="actiontaking">
                                    Communicating To
                                    <span class="p-invalid p-error">*</span>
                                </label>
                                <Dropdown id="actiontaking" v-model="evt.actiontaking" :options="actionTakingList"
                                    optionLabel="label" placeholder="Select ..." appendTo="body"></Dropdown>
                                <small class="p-invalid p-error" v-if="v$.evt.actiontaking.$error">{{
                                    v$.evt.actiontaking.$errors[0].$message
                                }}</small>
                            </div>
                            <div class="p-field p-col-12 p-md-2 p-d-flex p-pt-2">
                                <Button v-if="!showLoader" label="Submit" icon="pi pi-check"
                                    class="p-button-text p-ml-auto" @click="addUpdateSuperAdminTemplateEvents"
                                    style="width: 90px"></Button>
                                <Button v-show="showLoader" icon="pi pi-spin pi-spinner indigo-color"
                                    class="p-button-text p-ml-auto" style="width: 90px"></Button>

                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel header="Whatsapp Settings" :disabled="!this.tabOpenStatus.includes(1)">
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-12 p-md-4">
                                <label>Whats App status </label>
                                <div class="p-field-radiobutton">
                                    <RadioButton id="wp_status_active" name="option" v-bind:value="1"
                                        v-model="wp.wp_statustype" :checked="wp.wp_statustype == 1" />
                                    <label for="wp_status_active" class="p-mr-2">Active</label>
                                    <RadioButton id="wp_status_Inactive" name="option" v-bind:value="0"
                                        v-model="wp.wp_statustype" :checked="wp.wp_statustype == 0" />
                                    <label for="wp_status_Inactive" class="p-mr-2">Inactive</label>
                                </div>
                            </div>
                            <div class="p-field p-col-12 p-md-4">
                                <label for="templateName">
                                    Template Name
                                    <span class="p-invalid p-error">*</span>
                                </label>
                                <!-- @input="isAlreadyPresentTemplateName($event)" -->
                                <InputText id="templateName" v-model.trim="wp.templateName" required="true"
                                    maxlength="50" autofocus :class="{ 'p-invalid': submitted && !wp.templateName }" />
                                <small class="p-invalid p-error" v-if="v$.wp.templateName.$error">{{
                                    v$.wp.templateName.$errors[0].$message
                                }}</small>
                                <small class="p-invalid p-error" v-if="foundUniqueIdErrorMsg">{{ foundUniqueIdErrorMsg
                                    }}</small>

                            </div>
                            <!-- <div class="p-field p-col-12 p-md-4">
                                <label for="shorttemplateName">
                                    Template For
                                    <span class="p-invalid p-error">*</span>
                                </label>
                                <Dropdown id="shorttemplateName" v-model="wp.shorttemplateName"
                                    :options="shorttemplateNameList" optionLabel="label" placeholder="Select ..."
                                    appendTo="body"></Dropdown>

                            // <InputText id="shorttemplateName" class="p-text-capitalize" v-model.trim="shorttemplateName"
                            //  required="true" maxlength="100" /> 
                                <small class="p-invalid p-error" v-if="v$.wp.shorttemplateName.$error">{{
                                    v$.wp.shorttemplateName.$errors[0].$message
                                }}</small>
                            </div> -->
                            <div class="p-field p-col-12 p-md-4">
                                <label for="templateBody">
                                    Template Body
                                    <span class="p-invalid p-error">*</span>
                                </label>
                                <Textarea id="templateBody" v-model="wp.templateBody" :autoResize="true" rows="3"
                                    cols="30" placeholder="enter template body" autofocus
                                    :class="{ 'p-invalid': submitted && !wp.templateBody }" />
                                <small class="p-invalid p-error" v-if="v$.wp.templateBody.$error">{{
                                    v$.wp.templateBody.$errors[0].$message
                                }}</small>
                            </div>
                            <div class="p-field p-col-12 p-md-4">
                                <label for="template_variables">
                                    Template Variables
                                    <!-- <span class="p-invalid p-error">*</span> -->
                                </label>
                                <Textarea id="template_variables" :autoResize="true" rows="3" cols="30"
                                    v-model.trim="wp.template_variables" required="true"
                                    placeholder="enter template variables" autofocus />
                                <!-- <small class="p-invalid p-error" v-if="v$.wp.template_variables.$error">{{
                            v$.wp.template_variables.$errors[0].$message
                        }}</small> -->
                            </div>
                            <div class="p-field p-col-12 p-md-4">
                                <label for="language">
                                    Language
                                    <span class="p-invalid p-error">*</span>
                                </label>
                                <Dropdown id="language" v-model="wp.language" :options="languageList"
                                    optionLabel="label" placeholder="Select ..." appendTo="body"></Dropdown>
                                <small class="p-invalid p-error" v-if="v$.wp.language.$error">{{
                                    v$.wp.language.$errors[0].$message
                                }}</small>
                            </div>
                            <div class="p-field p-col-12 p-md-4">
                                <label for="template_reply_type">
                                    Reply Type
                                    <!-- <span class="p-invalid p-error">*</span> -->
                                </label>
                                <Dropdown id="template_reply_type" v-model="wp.template_reply_type"
                                    :options="templatereplytypeList" optionLabel="label" placeholder="Select ..."
                                    appendTo="body">
                                </Dropdown>
                                <!-- <small class="p-invalid p-error" v-if="v$.wp.template_reply_type.$error">{{
                            v$.wp.template_reply_type.$errors[0].$message
                        }}</small> -->
                            </div>
                            <div class="p-field p-col-12 p-md-8">
                                <label for="next_template_name">
                                    Next Template Name
                                    <!-- <span class="p-i   nvalid p-error">*</span> -->
                                </label>
                                <Dropdown id="next_template_name" showClear v-model="wp.next_template_name"
                                    :options="templateEventList" optionLabel="label" placeholder="Select ..."
                                    appendTo="body">
                                </Dropdown>
                                <!-- <small class="p-invalid p-error" v-if="v$.wp.next_template_name.$error">{{
                            v$.wp.next_template_name.$errors[0].$message
                        }}</small> -->
                            </div>

                        </div>
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-12 p-md-4">
                                <label>Header status <span class="p-invalid p-error"> *</span></label>
                                <div class="p-field-radiobutton">
                                    <RadioButton id="headerstatusavl" name="option" v-bind:value="1"
                                        v-model="wp.headerstatus" :checked="wp.headerstatus == 1" />
                                    <label for="headerstatusavl" class="p-mr-2">Available</label>
                                    <RadioButton id="headerstatusnotavl" name="option" v-bind:value="0"
                                        v-model="wp.headerstatus" :checked="wp.headerstatus == 0" />
                                    <label for="headerstatusnotavl" class="p-mr-2">Not Available</label>
                                </div>
                            </div>

                            <div class="p-field p-col-12 p-md-4" v-if="wp.headerstatus == 1">
                                <label for="header_file_type">
                                    Header File Type
                                    <!-- <span class="p-invalid p-error">*</span> -->
                                </label>
                                <Dropdown id="header_file_type" showClear v-model="wp.header_file_type"
                                    :options="headerFileTypeList" optionLabel="label" placeholder="Select ..."
                                    @change="clearuploadimage()" appendTo="body">
                                </Dropdown>
                                <div v-if="wp.headerstatus == 1">
                                    <small class="p-invalid p-error" v-if="v$.wp.header_file_type.$error">{{
                                        v$.wp.header_file_type.$errors[0].$message
                                    }}</small>
                                </div>
                            </div>

                            <div class="p-field p-col-12 p-md-4" v-if="wp.headerstatus == 1 && wp.header_file_type">
                                <label>Upload Header <span class="p-invalid p-error">*</span></label>
                                <div v-if="wp.header_file_type.value == 1">
                                    <FileUpload v-model="wp.header_file_url" mode="basic" ref="header_file_url_ref"
                                        :maxFileSize="1000000" accept="image/*" chooseLabel="Upload Header Image" auto
                                        @select="onSelectProfile($event)">
                                    </FileUpload>
                                    <span v-if="wp.header_file_url_edit && profileUrl == null">
                                        <img class="p-pt-2" role="presentation" alt="edit"
                                            :src="wp.header_file_url_edit" width="120" height="120" />
                                    </span>
                                </div>
                                <div v-if="wp.header_file_type.value == 2">
                                    <FileUpload mode="basic" ref="docuploadref" class="custom-upload-btn-ultima"
                                        accept="pdf/*" v-model="wp.header_file_url" chooseLabel="Upload Header Document"
                                        v-on:change="onSelectProfile()" />
                                </div>
                                <div v-if="wp.header_file_type.value == 3">
                                    <FileUpload mode="basic" ref="videouploadref" class="custom-upload-btn-ultima"
                                        accept="mp4/*" v-model="wp.header_file_url" chooseLabel="Upload Header Video"
                                        v-on:change="onSelectProfile()" />
                                </div>
                                <div v-if="wp.header_file_type.value == 4">
                                    <FileUpload v-model="wp.header_file_url" mode="basic" ref="tuneuploadeditref"
                                        :maxFileSize="1000000" accept="mp3/*" chooseLabel="Upload Header Tune" auto
                                        @select="onSelectProfile()" />
                                </div>
                                <div v-if="wp.header_file_type.value != 1">
                                    <a v-if="wp.header_file_url_edit != '' && wp.header_file_url_edit != null"
                                        class="view-btn" target="_blank" :href="wp.header_file_url_edit">Preview</a>
                                </div>
                                <small class="p-invalid p-error" v-if="profileImageError">{{ profileImageError
                                    }}</small>
                                <div v-if="wp.headerstatus == 1 && wp.header_file_type && profileImageError == ''">
                                    <small class="p-invalid p-error" v-if="v$.wp.header_file_url.$error">{{
                                        v$.wp.header_file_url.$errors[0].$message
                                    }}</small>
                                </div>
                                <div>
                                    <div v-if="wp.header_file_type.value == 1">
                                        <img v-if="profileUrl" class="p-pt-2" role="presentation" :alt="profileUrl.name"
                                            :src="profileUrl.url" width="120" height="120" />
                                    </div>
                                    <div v-if="wp.header_file_type.value != 1">
                                        <div class="p-field p-col-12 p-md-12" v-if="profileUrl != null">{{
                                            profileUrl.name
                                        }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div class="p-fluid p-formgrid p-grid">
                                <div class="p-field p-col-12 p-md-4">
                                    <label for="first_button_name">
                                        First Button Name
                                    </label>
                                    <InputText id="first_button_name" v-model.trim="wp.first_button_name"
                                        required="true" maxlength="25" />
                                </div>
                                <div class="p-field p-col-12 p-md-8" v-if="wp.first_button_name">
                                    <label for="first_button_template_name">
                                        First Button Template Name

                                        <span class="p-invalid p-error" v-if="wp.first_button_name != ''">*</span>
                                    </label>
                                    <Dropdown id="first_button_template_name" showClear filter
                                        v-model="wp.first_button_template_name" :options="templateEventList"
                                        optionLabel="label" placeholder="Select ..." appendTo="body">
                                    </Dropdown>
                                    <div v-if="wp.first_button_name != ''">
                                        <small class="p-invalid p-error"
                                            v-if="v$.wp.first_button_template_name.$error">{{
                                                v$.wp.first_button_template_name.$errors[0].$message
                                            }}</small>
                                    </div>
                                </div>
                            </div>

                            <div class="p-fluid p-formgrid p-grid">
                                <div class="p-field p-col-12 p-md-4">
                                    <label for="second_button_name">
                                        Second Button Name
                                    </label>
                                    <InputText id="second_button_name" v-model.trim="wp.second_button_name"
                                        maxlength="25" />
                                </div>
                                <div class="p-field p-col-12 p-md-8" v-if="wp.second_button_name">
                                    <label for="second_button_template_name">
                                        Second Button Template Name
                                        <span class="p-invalid p-error" v-if="wp.second_button_name != ''">*</span>

                                    </label>
                                    <Dropdown id="second_button_template_name" showClear filter
                                        v-model="wp.second_button_template_name" :options="templateEventList"
                                        optionLabel="label" placeholder="Select ..." appendTo="body">
                                    </Dropdown>
                                    <div v-if="second_button_name != ''">
                                        <small class="p-invalid p-error"
                                            v-if="v$.wp.second_button_template_name.$error">{{
                                                v$.wp.second_button_template_name.$errors[0].$message
                                            }}</small>
                                    </div>
                                </div>
                            </div>

                            <div class="p-fluid p-formgrid p-grid">
                                <div class="p-field p-col-12 p-md-4">
                                    <label for="third_button_name">
                                        Third Button Name
                                    </label>
                                    <InputText id="third_button_name" v-model.trim="wp.third_button_name"
                                        maxlength="25" />
                                </div>
                                <div class="p-field p-col-12 p-md-8" v-if="wp.third_button_name">
                                    <label for="third_button_template_name">
                                        Third Button Template Name
                                        <span class="p-invalid p-error" v-if="wp.third_button_name != ''">*</span>
                                    </label>
                                    <Dropdown id="third_button_template_name" showClear filter
                                        v-model="wp.third_button_template_name" :options="templateEventList"
                                        optionLabel="label" placeholder="Select ..." appendTo="body">
                                    </Dropdown>
                                    <div v-if="third_button_name != ''">
                                        <small class="p-invalid p-error"
                                            v-if="v$.wp.third_button_template_name.$error">{{
                                                v$.wp.third_button_template_name.$errors[0].$message
                                            }}</small>
                                    </div>
                                </div>
                            </div>
                            <Button v-if="!showLoader" label="Submit" icon="pi pi-check" class="p-button-text p-ml-auto"
                                @click="addWhatsAppTemplateSettings" style="width: 90px"></Button>
                            <Button v-show="showLoader" icon="pi pi-spin pi-spinner indigo-color"
                                class="p-button-text p-ml-auto" style="width: 90px"></Button>
                        </div>
                    </TabPanel>
                    <TabPanel header="Email" :disabled="!this.tabOpenStatus.includes(4)">
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-12 p-md-2">
                                <label>Email status </label>
                                <div class="p-field-radiobutton">
                                    <RadioButton id="email_status_active" name="option" v-bind:value="1"
                                        v-model="em.email_statustype" :checked="em.email_statustype == 1" />
                                    <label for="email_status_active" class="p-mr-2">Active</label>
                                    <RadioButton id="email_status_Inactive" name="option" v-bind:value="0"
                                        v-model="em.email_statustype" :checked="em.email_statustype == 0" />
                                    <label for="email_status_Inactive" class="p-mr-2">Inactive</label>
                                </div>
                            </div>
                            <div class="p-field p-col-12 p-md-5">
                                <label for="email_subjectId">
                                    Email Subject
                                    <span class="p-invalid p-error">*</span>
                                </label>
                                <InputText id="email_subjectId" class="p-text-capitalize"
                                    v-model.trim="em.email_subject" required="true" autofocus
                                    :class="{ 'p-invalid': submitted && !em.email_subject }" />
                                <small class="p-invalid p-error" v-if="v$.em.email_subject.$error">{{
                                    v$.em.email_subject.$errors[0].$message
                                }}</small>
                            </div>
                            <div class="p-field p-col-12 p-md-5">
                                <label for="email_variables">
                                    Email Template Variables
                                </label>
                                <Textarea id="email_variables" v-model="em.email_variables" :autoResize="true" rows="3"
                                    cols="30" required="true" />
                            </div>
                            <!---------- Email Template Body Start ----------------------------------------------->
                            <div class="p-field p-col-12 p-md-12">
                                <div class="p-d-flex p-ai-center p-jc-between p-flex-column p-flex-lg-row p-mb-1">
                                    <label for="email_body">
                                        Email Template Body
                                        <span class="p-invalid p-error">*</span>
                                    </label>
                                    <a @click="updatePreview" class="showmore p-mr-2" v-if="previewHtmlStatus">Source
                                        Code</a>
                                    <a @click="updatePreview" class="showmore p-mr-2"
                                        v-if="!previewHtmlStatus">Preview</a>
                                </div>
                                <div class="source-code-overflow-custom-css">
                                    <Textarea id="email_body" v-model="em.email_body" :autoResize="true" rows="20"
                                        cols="30" required="true" autofocus
                                        :class="{ 'p-invalid': submitted && !em.email_body }"
                                        v-if="!previewHtmlStatus" />
                                    <div class="preview" v-html="previewHtml" v-if="previewHtmlStatus"></div>
                                </div>
                                <small class="p-invalid p-error" v-if="v$.em.email_body.$error">{{
                                    v$.em.email_body.$errors[0].$message
                                }}</small>
                            </div>
                            <!---------- Email Template Body End ------------------------------------------------->
                            <div class="p-d-flex p-pt-4">
                                <Button v-if="!showLoader" label="Submit" icon="pi pi-check"
                                    class="p-button-text p-ml-auto" @click="addUpdateEventEmailTemplateSettings"
                                    style="width: 90px"></Button>
                                <Button v-show="showLoader" icon="pi pi-spin pi-spinner indigo-color"
                                    class="p-button-text p-ml-auto" style="width: 90px"></Button>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel header="RCS Settings" :disabled="!this.tabOpenStatus.includes(2)">
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-12 p-md-4">
                                <label>RCS status </label>
                                <div class="p-field-radiobutton">
                                    <RadioButton id="rcs_status_active" name="option" v-bind:value="1"
                                        v-model="rcs.rcs_statustype" :checked="rcs.rcs_statustype == 1" />
                                    <label for="rcs_status_active" class="p-mr-2">Active</label>
                                    <RadioButton id="rcs_status_Inactive" name="option" v-bind:value="0"
                                        v-model="rcs.rcs_statustype" :checked="rcs.rcs_statustype == 0" />
                                    <label for="rcs_status_Inactive" class="p-mr-2">Inactive</label>
                                </div>
                            </div>
                            <div class="p-field p-col-12 p-md-4">
                                <label for="templateName">
                                    Template Name
                                    <span class="p-invalid p-error">*</span>
                                </label>
                                <!-- @input="isAlreadyPresentTemplateName($event)" -->
                                <InputText id="templateName" v-model.trim="rcs.templateName" required="true"
                                    maxlength="50" autofocus :class="{ 'p-invalid': submitted && !rcs.templateName }" />
                                <small class="p-invalid p-error" v-if="v$.rcs.templateName.$error">{{
                                    v$.rcs.templateName.$errors[0].$message
                                }}</small>
                                <small class="p-invalid p-error" v-if="foundUniqueIdErrorMsg">{{ foundUniqueIdErrorMsg
                                    }}</small>

                            </div>
                            <!-- <div class="p-field p-col-12 p-md-4">
                                <label for="shorttemplateName">
                                    Template For
                                    <span class="p-invalid p-error">*</span>
                                </label>
                                <Dropdown id="shorttemplateName" v-model="rcs.shorttemplateName"
                                    :options="shorttemplateNameList" optionLabel="label" placeholder="Select ..."
                                    appendTo="body"></Dropdown>

                            // <InputText id="shorttemplateName" class="p-text-capitalize" v-model.trim="shorttemplateName"
                            //  required="true" maxlength="100" /> 
                                <small class="p-invalid p-error" v-if="v$.rcs.shorttemplateName.$error">{{
                                    v$.rcs.shorttemplateName.$errors[0].$message
                                }}</small>
                            </div> -->
                            <div class="p-field p-col-12 p-md-4">
                                <label for="templateBody">
                                    Template Body
                                    <span class="p-invalid p-error">*</span>
                                </label>
                                <Textarea id="templateBody" v-model="rcs.templateBody" :autoResize="true" rows="3"
                                    cols="30" placeholder="enter template body" autofocus
                                    :class="{ 'p-invalid': submitted && !rcs.templateBody }" />
                                <small class="p-invalid p-error" v-if="v$.rcs.templateBody.$error">{{
                                    v$.rcs.templateBody.$errors[0].$message
                                }}</small>
                            </div>
                            <div class="p-field p-col-12 p-md-4">
                                <label for="template_variables">
                                    Template Variables
                                    <!-- <span class="p-invalid p-error">*</span> -->
                                </label>
                                <Textarea id="template_variables" :autoResize="true" rows="3" cols="30"
                                    v-model.trim="rcs.template_variables" required="true"
                                    placeholder="enter template variables" autofocus />
                                <!-- <small class="p-invalid p-error" v-if="v$.rcs.template_variables.$error">{{
                            v$.rcs.template_variables.$errors[0].$message
                        }}</small> -->
                            </div>
                            <div class="p-field p-col-12 p-md-4">
                                <label for="language">
                                    Language
                                    <span class="p-invalid p-error">*</span>
                                </label>
                                <Dropdown id="language" v-model="rcs.language" :options="languageList"
                                    optionLabel="label" placeholder="Select ..." appendTo="body"></Dropdown>
                                <small class="p-invalid p-error" v-if="v$.rcs.language.$error">{{
                                    v$.rcs.language.$errors[0].$message
                                }}</small>
                            </div>
                            <div class="p-field p-col-12 p-md-4">
                                <label for="template_reply_type">
                                    Reply Type
                                    <!-- <span class="p-invalid p-error">*</span> -->
                                </label>
                                <Dropdown id="template_reply_type" v-model="rcs.template_reply_type"
                                    :options="templatereplytypeList" optionLabel="label" placeholder="Select ..."
                                    appendTo="body">
                                </Dropdown>
                                <!-- <small class="p-invalid p-error" v-if="v$.rcs.template_reply_type.$error">{{
                            v$.rcs.template_reply_type.$errors[0].$message
                        }}</small> -->
                            </div>
                            <div class="p-field p-col-12 p-md-8">
                                <label for="next_template_name">
                                    Next Template Name
                                    <!-- <span class="p-i   nvalid p-error">*</span> -->
                                </label>
                                <Dropdown id="next_template_name" showClear v-model="rcs.next_template_name"
                                    :options="templateEventList" optionLabel="label" placeholder="Select ..."
                                    appendTo="body">
                                </Dropdown>
                                <!-- <small class="p-invalid p-error" v-if="v$.rcs.next_template_name.$error">{{
                            v$.rcs.next_template_name.$errors[0].$message
                        }}</small> -->
                            </div>

                            <div class="p-field p-col-12 p-md-4">
                                <label>Header status <span class="p-invalid p-error"> *</span></label>
                                <div class="p-field-radiobutton">
                                    <RadioButton id="headerstatusavl" name="option" v-bind:value="1"
                                        v-model="rcs.headerstatus" :checked="rcs.headerstatus == 1" />
                                    <label for="headerstatusavl" class="p-mr-2">Available</label>
                                    <RadioButton id="headerstatusnotavl" name="option" v-bind:value="0"
                                        v-model="rcs.headerstatus" :checked="rcs.headerstatus == 0" />
                                    <label for="headerstatusnotavl" class="p-mr-2">Not Available</label>
                                </div>
                            </div>

                            <div class="p-field p-col-12 p-md-4" v-if="rcs.headerstatus == 1">
                                <label for="header_file_type">
                                    Header File Type
                                    <!-- <span class="p-invalid p-error">*</span> -->
                                </label>
                                <Dropdown id="header_file_type" showClear v-model="rcs.header_file_type"
                                    :options="headerFileTypeList" optionLabel="label" placeholder="Select ..."
                                    @change="clearuploadimageRCS()" appendTo="body">
                                </Dropdown>
                                <!-- <small class="p-invalid p-error" v-if="v$.rcs.header_file_type.$error">{{
                            v$.rcs.header_file_type.$errors[0].$message
                        }}</small> -->
                            </div>

                            <div class="p-field p-col-12 p-md-4" v-if="rcs.headerstatus == 1 && rcs.header_file_type">
                                <label>Upload Header <span class="p-invalid p-error">*</span></label>
                                <div v-if="rcs.header_file_type.value == 1">
                                    <FileUpload v-model="rcs.header_file_url" mode="basic" ref="header_file_url_ref"
                                        :maxFileSize="1000000" accept="image/*" chooseLabel="Upload Header Image" auto
                                        @select="onSelectProfileRCS($event)">
                                    </FileUpload>
                                    <span v-if="rcs.header_file_url_edit && profileUrlRCS == null">
                                        <img class="p-pt-2" role="presentation" alt="edit"
                                            :src="rcs.header_file_url_edit" width="120" height="120" />
                                    </span>
                                </div>
                                <div v-if="rcs.header_file_type.value == 2">
                                    <FileUpload mode="basic" ref="videouploadref" class="custom-upload-btn-ultima"
                                        accept="pdf/*" v-model="rcs.header_file_url"
                                        chooseLabel="Upload Header Document" v-on:change="onSelectProfileRCS()" />
                                </div>
                                <div v-if="rcs.header_file_type.value == 3">
                                    <FileUpload mode="basic" ref="docuploadref" class="custom-upload-btn-ultima"
                                        accept="mp4/*" v-model="rcs.header_file_url" chooseLabel="Upload Header Video"
                                        v-on:change="onSelectProfileRCS()" />
                                </div>
                                <div v-if="rcs.header_file_type.value == 4">
                                    <FileUpload v-model="rcs.header_file_url" mode="basic" ref="tuneuploadeditref"
                                        :maxFileSize="1000000" accept="mp3/*" chooseLabel="Upload Header Tune" auto
                                        @select="onSelectProfileRCS()" />
                                </div>
                                <small class="p-invalid p-error" v-if="profileImageErrorRCS">{{ profileImageErrorRCS
                                    }}</small>

                                <div>
                                    <div v-if="rcs.header_file_type.value == 1">
                                        <img v-if="profileUrlRCS" class="p-pt-2" role="presentation"
                                            :alt="profileUrlRCS.name" :src="profileUrlRCS.url" width="120"
                                            height="120" />
                                    </div>
                                    <div v-if="rcs.header_file_type.value != 1">
                                        <div class="p-field p-col-12 p-md-12" v-if="profileUrlRCS != null">{{
                                            profileUrlRCS.name
                                        }}</div>
                                    </div>
                                </div>
                            </div>

                            <div class="p-field p-col-12 p-md-4">
                                <label for="first_button_name">
                                    First Button Name
                                </label>
                                <InputText id="first_button_name" class="p-text-capitalize"
                                    v-model.trim="rcs.first_button_name" required="true" maxlength="20" />
                            </div>
                            <div class="p-field p-col-12 p-md-8" v-if="rcs.first_button_name">
                                <label for="first_button_template_name">
                                    First Button Template Name

                                    <span class="p-invalid p-error" v-if="rcs.first_button_name != ''">*</span>
                                </label>
                                <Dropdown id="first_button_template_name" showClear filter
                                    v-model="rcs.first_button_template_name" :options="templateEventList"
                                    optionLabel="label" placeholder="Select ..." appendTo="body">
                                </Dropdown>
                                <div v-if="rcs.first_button_name != ''">
                                    <small class="p-invalid p-error" v-if="v$.rcs.first_button_template_name.$error">{{
                                        v$.rcs.first_button_template_name.$errors[0].$message
                                    }}</small>
                                </div>
                            </div>
                            <div class="p-field p-col-12 p-md-4">
                                <label for="second_button_name">
                                    Second Button Name
                                </label>
                                <InputText id="second_button_name" class="p-text-capitalize"
                                    v-model.trim="rcs.second_button_name" maxlength="20" />
                            </div>

                            <div class="p-field p-col-12 p-md-8" v-if="rcs.second_button_name">
                                <label for="second_button_template_name">
                                    Second Button Template Name
                                    <span class="p-invalid p-error" v-if="rcs.second_button_name != ''">*</span>

                                </label>
                                <Dropdown id="second_button_template_name" showClear filter
                                    v-model="rcs.second_button_template_name" :options="templateEventList"
                                    optionLabel="label" placeholder="Select ..." appendTo="body">
                                </Dropdown>
                                <div v-if="second_button_name != ''">
                                    <small class="p-invalid p-error" v-if="v$.rcs.second_button_template_name.$error">{{
                                        v$.rcs.second_button_template_name.$errors[0].$message
                                    }}</small>
                                </div>
                            </div>
                            <div class="p-field p-col-12 p-md-4">
                                <label for="third_button_name">
                                    Third Button Name
                                </label>
                                <InputText id="third_button_name" class="p-text-capitalize"
                                    v-model.trim="rcs.third_button_name" maxlength="20" />
                            </div>
                            <div class="p-field p-col-12 p-md-8" v-if="rcs.third_button_name">
                                <label for="third_button_template_name">
                                    Third Button Template Name
                                    <span class="p-invalid p-error" v-if="rcs.third_button_name != ''">*</span>
                                </label>
                                <Dropdown id="third_button_template_name" showClear filter
                                    v-model="rcs.third_button_template_name" :options="templateEventList"
                                    optionLabel="label" placeholder="Select ..." appendTo="body">
                                </Dropdown>
                                <div v-if="third_button_name != ''">
                                    <small class="p-invalid p-error" v-if="v$.rcs.third_button_template_name.$error">{{
                                        v$.rcs.third_button_template_name.$errors[0].$message
                                    }}</small>
                                </div>
                            </div>
                            <div class="p-d-flex p-pt-4">
                                <Button v-if="!showLoader" label="Submit" icon="pi pi-check"
                                    class="p-button-text p-ml-auto" @click="addUpdateEventRCSTemplateSettings"
                                    style="width: 90px"></Button>
                                <Button v-show="showLoader" icon="pi pi-spin pi-spinner indigo-color"
                                    class="p-button-text p-ml-auto" style="width: 90px"></Button>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel header="SMS Settings" :disabled="!this.tabOpenStatus.includes(3)">
                        <div class="p-fluid p-formgrid p-grid">

                            <div class="p-field p-col-12 p-md-6">
                                <label>SMS status </label>
                                <div class="p-field-radiobutton">
                                    <RadioButton id="sms_status_active" name="option" v-bind:value="1"
                                        v-model="sms.sms_statustype" :checked="sms.sms_statustype == 1" />
                                    <label for="sms_status_active" class="p-mr-2">Active</label>
                                    <RadioButton id="sms_status_Inactive" name="option" v-bind:value="0"
                                        v-model="sms.sms_statustype" :checked="sms.sms_statustype == 0" />
                                    <label for="sms_status_Inactive" class="p-mr-2">Inactive</label>
                                </div>
                            </div>
                            <div class="p-field p-col-12 p-md-6">
                                <label for="service_provider_template_id">
                                    Service Provider SMS Template Id
                                    <span class="p-invalid p-error">*</span>
                                </label>
                                <InputText id="service_provider_template_id"
                                    v-model.trim="sms.service_provider_template_id" maxlength="20" />
                                <small class="p-invalid p-error" v-if="v$.sms.service_provider_template_id.$error">{{
                                    v$.sms.service_provider_template_id.$errors[0].$message
                                    }}</small>
                            </div>
                            <div class="p-field p-col-12 p-md-6">
                                <label for="template_id">
                                    Vendor Template Id
                                    <span class="p-invalid p-error">*</span>
                                </label>
                                <InputText id="template_id" v-model.trim="sms.template_id" maxlength="20" />
                                <small class="p-invalid p-error" v-if="v$.sms.template_id.$error">{{
                                    v$.sms.template_id.$errors[0].$message
                                    }}</small>
                            </div>
                            <div class="p-field p-col-12 p-md-6">
                                <label for="sms_header_nameId">
                                    SMS Header Name
                                    <span class="p-invalid p-error">*</span>
                                </label>
                                <InputText id="sms_header_nameId" style="text-transform: uppercase;"
                                    v-model.trim="sms.sms_header_name" maxlength="6" minlength="6" required="true"
                                    autofocus :class="{ 'p-invalid': submitted && !sms.sms_header_name }" />
                                <small class="p-invalid p-error" v-if="v$.sms.sms_header_name.$error">{{
                                    v$.sms.sms_header_name.$errors[0].$message
                                    }}</small>
                            </div>

                            <div class="p-field p-col-12 p-md-6">
                                <label for="sms_body">
                                    SMS Template Body
                                    <span class="p-invalid p-error">*</span>
                                </label>
                                <Textarea id="sms_body" class="p-text-capitalize" v-model="sms.sms_body"
                                    :autoResize="true" rows="3" cols="30" required="true" autofocus
                                    :class="{ 'p-invalid': submitted && !sms.sms_body }" />
                                <small class="p-invalid p-error" v-if="v$.sms.sms_body.$error">{{
                                    v$.sms.sms_body.$errors[0].$message
                                    }}</small>
                            </div>
                            <div class="p-field p-col-12 p-md-6">
                                <label for="sms_variables">
                                    SMS Template Variables
                                    <!-- <span class="p-invalid p-error">*</span> -->
                                </label>
                                <Textarea id="sms_variables" v-model="sms.sms_variables" :autoResize="true" rows="3"
                                    cols="30" required="true" />
                                <!-- <small class="p-invalid p-error" v-if="v$.sms.sms_variables.$error">{{
                                            v$.sms.sms_variables.$errors[0].$message
                                        }}</small> -->
                            </div>
                            <div class="p-d-flex p-pt-4">
                                <Button v-if="!showLoader" label="Submit" icon="pi pi-check"
                                    class="p-button-text p-ml-auto" @click="addUpdateAdminEventSMSTemplateSettings"
                                    style="width: 90px"></Button>
                                <Button v-show="showLoader" icon="pi pi-spin pi-spinner indigo-color"
                                    class="p-button-text p-ml-auto" style="width: 90px"></Button>
                            </div>
                        </div>
                    </TabPanel>
                </TabView>
            </div>
        </div>
    </div>
</template>

<script>
import ApiService from '../../service/ApiService';
import useValidate from '@vuelidate/core';
import router from '@/router';
import { useRoute } from 'vue-router';
import { required, helpers, requiredIf } from '@vuelidate/validators';
import { inject } from 'vue';

export default {
    inject: ['global'],
    data() {
        const globalData = inject('global');
        return {
            v$: useValidate(),
            setting_id: 0,
            tabOpenStatus: [],
            evt: {
                ChannelName: '',
                eventName: '',
                evtStatusType: 0,
            },
            wp: {
                ta_id: 0,
                wp_statustype: 0,
                templateName: '',
                templateAction: '',
                templateEvent: '',
                templateBody: '',
                template_variables: '',
                language: '',
                template_reply_type: '',
                next_template_name: '',
                first_button_name: '',
                first_button_template_name: '',
                second_button_name: '',
                second_button_template_name: '',
                third_button_name: '',
                third_button_template_name: '',
                header_file_type: '',
                headerstatus: 0,
                header_file_url: null,
                header_file_url_edit: null,
            },
            rcs: {
                ta_id: 0,
                rcs_statustype: 0,
                templateName: '',
                templateAction: '',
                templateEvent: '',
                templateBody: '',
                template_variables: '',
                language: '',
                template_reply_type: '',
                next_template_name: '',
                first_button_name: '',
                first_button_template_name: '',
                second_button_name: '',
                second_button_template_name: '',
                third_button_name: '',
                third_button_template_name: '',
                header_file_type: '',
                headerstatus: 0,
                header_file_url: null,
                header_file_url_edit: null,
            },
            em: {
                ta_id: 0,
                email_subject: '',
                email_statustype: 0,
                email_body: '',
                email_variables: '',
            },
            sms: {
                ta_id: 0,
                sms_header_name: '',
                sms_statustype: 0,
                sms_body: '',
                sms_variables: '',
                service_provider_template_id: '',
                template_id: '',
            },

            ChannelIdList: globalData.state.ChannelIdList,
            headerFileTypeList: globalData.state.headerFileTypeList,
            languageList: [],
            templatereplytypeList: globalData.state.templateReplyTypeList,
            templateEventList: [],
            showloaderpage: false,
            showLoader: false,
            submitted: false,
            errormsg: '',
            systemUrlErr: '',
            localAdminActionId: '',
            localClientId: '',
            foundUniqueIdErrorMsg: '',
            te_row_id: 0,
            ta_id: 0,
            routeParam: '',
            wp_data: [],
            sms_data: [],
            rcs_data: [],
            localClientName: '',
            headerFileTypeta48: 0,

            profileImageError: '',
            file: '',
            profileUrl: null,
            allowedExtensions: '',
            docuploadref: '',
            videouploadref: '',
            tuneuploadeditref: '',

            profileImageErrorRCS: '',
            profileUrlRCS: null,
            actionTakingList: globalData.state.actionTakingListForAdmin,
            previewHtml: null,
            previewHtmlStatus: false
        };
    },
    validations() {
        return {
            evt: {
                ChannelName: { required: helpers.withMessage('Please select channel name', required) },
                eventName: { required: helpers.withMessage('Please enter event name', required) },
                actiontaking: { required: helpers.withMessage('Please select communicating to', required) },
            },
            wp: {
                language: { required: helpers.withMessage('Please select language', required) },
                templateName: { required: helpers.withMessage('Please enter template name', required) },
                // shorttemplateName: { required: helpers.withMessage('Please enter short template name', required) },
                templateBody: { required: helpers.withMessage('Please enter template body', required) },
                headerstatus: { required: helpers.withMessage('Please enter header status', required) },
                header_file_type: {
                    requiredIf: helpers.withMessage("Please select header file type",
                        requiredIf(
                            this.wp.headerstatus == 1
                        )
                    ),
                },
                header_file_url: {
                    requiredIf: helpers.withMessage("Please upload header file",
                        requiredIf(
                            this.wp.headerstatus == 1 && (this.wp.header_file_url_edit == null || this.wp.header_file_url_edit == '')
                        )
                    ),
                },
                first_button_template_name: {
                    requiredIf: helpers.withMessage("Please select First button template",
                        requiredIf(
                            this.wp.first_button_name != '' && this.wp.first_button_name != null
                        )
                    ),
                },
                second_button_template_name: {
                    requiredIf: helpers.withMessage("Please select Second button template",
                        requiredIf(
                            this.wp.second_button_name != '' && this.wp.second_button_name != null
                        )
                    ),
                },
                third_button_template_name: {
                    requiredIf: helpers.withMessage("Please select Third button template",
                        requiredIf(
                            this.wp.third_button_name != '' && this.wp.third_button_name != null
                        )
                    ),
                },

            },
            rcs: {
                language: { required: helpers.withMessage('Please select language', required) },
                templateName: { required: helpers.withMessage('Please enter template name', required) },
                // shorttemplateName: { required: helpers.withMessage('Please enter short template name', required) },
                templateBody: { required: helpers.withMessage('Please enter template body', required) },
                // template_reply_type: { required: helpers.withMessage('Please enter template reply type', required) },
                // header_file_type: { required: helpers.withMessage('please select Header File type', required) },
                // template_variables: { required: helpers.withMessage('Please enter template variables', required) },
                // next_template_name: { required: helpers.withMessage('Please enter next template name', required) },
                //vendors_template_id: { required: helpers.withMessage('Please enter Vendor Template Id', required) },
                headerstatus: { required: helpers.withMessage('Please enter header status', required) },
                // header_file_url: {
                //     requiredIf: helpers.withMessage(
                //         "Please select header file",
                //         requiredIf(
                //             this.wp.headerstatus == 1
                //         )
                //     ),
                // },
                first_button_template_name: {
                    requiredIf: helpers.withMessage("Please select First button template",
                        requiredIf(
                            this.rcs.first_button_name != '' && this.rcs.first_button_name != null
                        )
                    ),
                },
                second_button_template_name: {
                    requiredIf: helpers.withMessage("Please select Second button template",
                        requiredIf(
                            this.rcs.second_button_name != '' && this.rcs.second_button_name != null
                        )
                    ),
                },
                third_button_template_name: {
                    requiredIf: helpers.withMessage("Please select Third button template",
                        requiredIf(
                            this.rcs.third_button_name != '' && this.rcs.third_button_name != null
                        )
                    ),
                },

            },
            em: {
                email_subject: { required: helpers.withMessage('Please eneter email subject', required) },
                // email_statustype: { required: helpers.withMessage('Please select the status', required) },
                email_body: { required: helpers.withMessage('Please select email body', required) },
            },
            sms: {
                sms_header_name: { required: helpers.withMessage('Please enter sms header required', required) },
                sms_body: { required: helpers.withMessage('Please select sms body', required) },
                service_provider_template_id: { required: helpers.withMessage('Please enter service provider template id', required) },
                template_id: { required: helpers.withMessage('Please enter vendor template Id', required) },
            },
        };
    },
    ApiService: null,
    interval: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        this.getLanguages();
        const route = useRoute();
        this.routeParam = route.params.eventId;
        this.localAdminActionId = localStorage.localSuperAdminActionId;
        // console.log(this.localAdminActionId);
        if (this.routeParam != 0) {
            this.getCurrentSuperAdminTemplateEvents({ actionId: this.localAdminActionId, eventId: this.routeParam });
        }
    },
    methods: {
        getLanguages() {
            this.ApiService.getLanguages().then((data) => {
                if (data.status == 200) {
                    this.languageList = data.data;
                } else {
                    this.languageList = '';
                }
            });
        },

        getSuperAdminWhatsAppTemplateEventsOnly() {
            this.loading = true;
            this.ApiService.getSuperAdminWhatsAppTemplateEventsOnly({ actionId: this.localAdminActionId }).then((data) => {
                if (data.status == 200) {
                    this.templateEventList = [];
                    var templateEventList = data.data;
                    let arrConverted = templateEventList;
                    for (let i = 0; i < arrConverted.length; i++) {
                        let vals = this.languageList.filter(function (item) {
                            return item.value == arrConverted[i]['ta21'];
                        });
                        if (vals.length > 0) {
                            let newElement = vals[0]['label'];
                            if (templateEventList[i]['label'] != null) {
                                this.templateEventList.push({
                                    'value': templateEventList[i]['value'],
                                    'label': templateEventList[i]['label'] + "(" + newElement + ")",
                                    'ta21': templateEventList[i]['ta21'],
                                });
                            }
                        }
                    }
                } else {
                    this.templateEventList = '';
                }
                this.loading = false;
            });
        },

        getSuperAdminRCSTemplateEventsOnly() {
            this.loading = true;
            this.ApiService.getSuperAdminRCSTemplateEventsOnly({ actionId: this.localAdminActionId }).then((data) => {
                if (data.status == 200) {
                    this.templateEventList = [];
                    var templateEventList = data.data;
                    let arrConverted = templateEventList;
                    for (let i = 0; i < arrConverted.length; i++) {
                        let vals = this.languageList.filter(function (item) {
                            return item.value == arrConverted[i]['ta36'];
                        });
                        if (vals.length > 0) {
                            let newElement = vals[0]['label'];
                            if (templateEventList[i]['label'] != null) {
                                this.templateEventList.push({
                                    'value': templateEventList[i]['value'],
                                    'label': templateEventList[i]['label'] + "(" + newElement + ")",
                                    'ta36': templateEventList[i]['ta36'],
                                });
                            }
                        }
                    }
                } else {
                    this.templateEventList = '';
                }
                this.loading = false;
            });
        },

        clearuploadimage() {
            this.profileImageError = '';
            this.profileUrl = '';
            this.wp.header_file_url_edit = null;
            this.wp.header_file_url = null;
        },

        clearuploadimageRCS() {
            this.profileImageErrorRCS = '';
            this.profileUrlRCS = '';
            this.rcs.header_file_url_edit = null;
            this.rcs.header_file_url = null;
        },

        getTabChangeSettings(e) {
            if (e.index == 2) {
                this.getCurrentSuperAdminEmailTemplateEvents();
            } else if (e.index == 3) {
                this.getCurrentSuperAdminRCSTemplateEvents();
            } else if (e.index == 4) {
                this.getCurrentSuperAdminSMSTemplateEvents();
            } else if (e.index == 1) {
                this.getCurrentSuperAdminWPTemplateEvents();
            }
        },


        getCurrentSuperAdminTemplateEvents() {
            this.loading = true;
            this.ApiService.getCurrentSuperAdminTemplateEvents({ actionId: this.localAdminActionId, eventId: this.routeParam }).then((data) => {
                if (data.status == 200) {
                    this.evt.eventName = data.result.tb3;
                    if (data.actionTakingData != null) {
                        let vals = this.actionTakingList.filter(function (item) {
                            return item.value == data.actionTakingData.ta52;
                        });
                        if (vals.length > 0) {
                            this.evt.actiontaking = vals[0];
                        }
                        this.setting_id = data.actionTakingData.ta1;
                    }
                    // console.log(this.setting_id);
                    if (data.result.tb4) {
                        this.tabOpenStatus = data.result.tb4;
                        let te4Array = data.result.tb4.split(',').map(Number);
                        this.evt.ChannelName = te4Array;
                    }
                    this.evt.evtStatusType = data.result.tb5;
                    this.loading = false;
                } else {
                    this.loading = false;
                }
                this.loading = false;
            });
        },

        getCurrentSuperAdminWPTemplateEvents() {
            this.loading = true;
            this.wp_data = '';
            this.ApiService.getCurrentSuperAdminWPTemplateEvents({ actionId: this.localAdminActionId, eventId: this.routeParam }).then((data) => {
                if (data.status == 200) {
                    this.wp_data = data.result;
                    this.ta_id = this.wp_data.ta1;
                    this.setting_id = this.wp_data.ta1;
                    this.wp.wp_statustype = this.wp_data.ta50;
                    this.wp.templateName = this.wp_data.ta18;
                    this.wp.templateBody = this.wp_data.ta19;
                    this.wp.template_variables = this.wp_data.ta20;
                    if (data.result.ta21) {
                        let languageListId = this.languageList.filter(function (item) {
                            return item.value == data.result.ta21;
                        });
                        if (languageListId.length > 0) {
                            this.wp.language = languageListId[0];
                        }
                    }

                    if (data.result.ta22) {
                        let replytypeId = this.templatereplytypeList.filter(function (item) {
                            return item.value == data.result.ta22;
                        });
                        if (replytypeId.length > 0) {
                            this.wp.template_reply_type = replytypeId[0];
                        }
                    }

                    this.ApiService.getSuperAdminWhatsAppTemplateEventsOnly({ actionId: this.localAdminActionId }).then((data) => {
                        if (data.status == 200) {
                            this.templateEventList = [];
                            var templateEventList = data.data;
                            let arrConverted = templateEventList;
                            for (let i = 0; i < arrConverted.length; i++) {
                                let vals = this.languageList.filter(function (item) {
                                    return item.value == arrConverted[i]['ta21'];
                                });
                                if (vals.length > 0) {
                                    let newElement = vals[0]['label'];
                                    if (templateEventList[i]['label'] != null) {
                                        this.templateEventList.push({
                                            'value': templateEventList[i]['value'],
                                            'label': templateEventList[i]['label'] + "(" + newElement + ")",
                                            'ta21': templateEventList[i]['ta21'],
                                        });
                                    }
                                }
                            }
                            let ta23 = +this.wp_data.ta23;
                            if (ta23) {
                                let templateEventnextbtn = this.templateEventList.filter(function (item) {
                                    return item.value == ta23;
                                });
                                if (templateEventnextbtn.length > 0) {

                                    this.wp.next_template_name = templateEventnextbtn[0];
                                }
                            }

                            let ta25 = +this.wp_data.ta25;
                            if (ta25) {
                                let templateEventfirstbtn = this.templateEventList.filter(function (item) {
                                    return item.value == ta25;
                                });
                                if (templateEventfirstbtn.length > 0) {
                                    this.wp.first_button_template_name = templateEventfirstbtn[0];
                                }
                            }

                            let ta27 = +this.wp_data.ta27;
                            if (ta27) {

                                let templateEventSecondbtn = this.templateEventList.filter(function (item) {
                                    return item.value == ta27;
                                });
                                if (templateEventSecondbtn.length > 0) {
                                    this.wp.second_button_template_name = templateEventSecondbtn[0];
                                }
                            }

                            let ta29 = +this.wp_data.ta29;
                            if (ta29) {

                                let templateEventthirdbtn = this.templateEventList.filter(function (item) {
                                    return item.value == ta29;
                                });
                                if (templateEventthirdbtn.length > 0) {
                                    this.wp.third_button_template_name = templateEventthirdbtn[0];
                                }
                            }

                        }
                    });

                    this.wp.ta_id = this.wp_data.ta1;
                    this.wp.first_button_name = this.wp_data.ta24;
                    this.wp.second_button_name = this.wp_data.ta26;
                    this.wp.third_button_name = this.wp_data.ta28;
                    this.wp.headerstatus = this.wp_data.ta30;

                    if (this.wp_data.ta30 == 1) {
                        if (this.wp_data.ta31) {
                            const val = this.getHeaderFileTypeFromURL(this.wp_data.ta31);
                            let header_file_type_id = this.headerFileTypeList.filter(function (item) {
                                return item.value == val; // change this to the headerfiletype
                            });
                            if (header_file_type_id.length > 0) {
                                this.wp.header_file_type = header_file_type_id[0];
                            }
                        }
                    }

                    if (this.wp_data.ta31 != null || this.wp_data.ta31 != '') {
                        this.wp.header_file_url_edit = this.wp_data.ta31;
                    }
                    this.loading = false;
                } else {
                    this.getSuperAdminWhatsAppTemplateEventsOnly();
                    this.loading = false;
                }
                this.loading = false;
            });
        },

        getCurrentSuperAdminRCSTemplateEvents() {
            this.loading = true;
            this.rcs_data = '';
            this.ApiService.getCurrentSuperAdminRCSTemplateEvents({ actionId: this.localAdminActionId, eventId: this.routeParam }).then((data) => {
                if (data.status == 200) {
                    this.rcs_data = data.result;
                    this.ta_id = this.rcs_data.ta1;
                    this.setting_id = this.rcs_data.ta1;
                    this.rcs.rcs_statustype = this.rcs_data.ta51;
                    this.rcs.templateName = this.rcs_data.ta33;
                    this.rcs.templateBody = this.rcs_data.ta34;
                    this.rcs.template_variables = this.rcs_data.ta35;

                    if (data.result.ta36) {
                        let languageListId = this.languageList.filter(function (item) {
                            return item.value == data.result.ta36;
                        });
                        if (languageListId.length > 0) {
                            this.rcs.language = languageListId[0];
                        }
                    }

                    if (data.result.ta37) {
                        let replytypeId = this.templatereplytypeList.filter(function (item) {
                            return item.value == data.result.ta37;
                        });
                        if (replytypeId.length > 0) {
                            this.rcs.template_reply_type = replytypeId[0];
                        }
                    }

                    this.ApiService.getSuperAdminRCSTemplateEventsOnly({ actionId: this.localAdminActionId }).then((data) => {
                        if (data.status == 200) {
                            this.templateEventList = [];
                            var templateEventList = data.data;
                            let arrConverted = templateEventList;
                            for (let i = 0; i < arrConverted.length; i++) {
                                let vals = this.languageList.filter(function (item) {
                                    return item.value == arrConverted[i]['ta36'];
                                });
                                if (vals.length > 0) {
                                    let newElement = vals[0]['label'];
                                    if (templateEventList[i]['label'] != null) {
                                        this.templateEventList.push({
                                            'value': templateEventList[i]['value'],
                                            'label': templateEventList[i]['label'] + "(" + newElement + ")",
                                            'ta36': templateEventList[i]['ta36'],
                                        });
                                    }
                                }
                            }
                            let ta38 = +this.rcs_data.ta38;
                            if (ta38) {
                                let templateEventnextbtn = this.templateEventList.filter(function (item) {
                                    return item.value == ta38;
                                });
                                if (templateEventnextbtn.length > 0) {

                                    this.rcs.next_template_name = templateEventnextbtn[0];
                                }
                            }

                            let ta40 = +this.rcs_data.ta40;
                            if (ta40) {
                                let templateEventfirstbtn = this.templateEventList.filter(function (item) {
                                    return item.value == ta40;
                                });
                                if (templateEventfirstbtn.length > 0) {
                                    this.rcs.first_button_template_name = templateEventfirstbtn[0];
                                }
                            }

                            let ta42 = +this.rcs_data.ta42;
                            if (ta42) {

                                let templateEventSecondbtn = this.templateEventList.filter(function (item) {
                                    return item.value == ta42;
                                });
                                if (templateEventSecondbtn.length > 0) {
                                    this.rcs.second_button_template_name = templateEventSecondbtn[0];
                                }
                            }

                            let ta44 = +this.rcs_data.ta44;
                            if (ta44) {

                                let templateEventthirdbtn = this.templateEventList.filter(function (item) {
                                    return item.value == ta44;
                                });
                                if (templateEventthirdbtn.length > 0) {
                                    this.rcs.third_button_template_name = templateEventthirdbtn[0];
                                }
                            }

                        }
                    });


                    this.rcs.ta_id = this.rcs_data.ta1;
                    this.rcs.first_button_name = this.rcs_data.ta39;
                    this.rcs.second_button_name = this.rcs_data.ta41;
                    this.rcs.third_button_name = this.rcs_data.ta43;
                    this.rcs.headerstatus = this.rcs_data.ta47;

                    if (data.result.ta47 == 1) {
                        let header_file_type_id = this.headerFileTypeList.filter(function (item) {
                            return item.value == 1;
                        });
                        if (header_file_type_id.length > 0) {
                            this.rcs.header_file_type = header_file_type_id[0];
                        }
                    }
                    if (this.rcs_data.ta48 != null || this.rcs_data.ta48 != '') {
                        this.rcs.header_file_url_edit = this.rcs_data.ta48;
                    }
                    this.loading = false;
                } else {
                    this.getSuperAdminRCSTemplateEventsOnly();
                    this.loading = false;
                }
                this.loading = false;
            });
        },

        getCurrentSuperAdminEmailTemplateEvents() {
            this.loading = true;
            this.wp_data = '';
            this.ApiService.getCurrentSuperAdminEmailTemplateEvents({ actionId: this.localAdminActionId, eventId: this.routeParam }).then((data) => {
                if (data.status == 200) {
                    this.em.email_statustype = data.result.ta14;
                    this.em.email_subject = data.result.ta15;
                    this.em.email_body = data.result.ta16;
                    this.em.ta_id = data.result.ta1;
                    this.setting_id = data.result.ta1;
                    this.em.email_variables = data.result.ta17;
                    this.loading = false;
                } else {
                    this.loading = false;
                }
                this.loading = false;
            });
        },

        getCurrentSuperAdminSMSTemplateEvents() {
            this.loading = true;
            this.sms_data = '';
            this.ApiService.getCurrentSuperAdminSMSTemplateEvents({ actionId: this.localAdminActionId, eventId: this.routeParam }).then((data) => {
                if (data.status == 200) {
                    this.sms.sms_statustype = data.result.ta4;
                    this.sms.sms_header_name = data.result.ta5;
                    this.sms.template_id = data.result.ta6;
                    this.sms.sms_body = data.result.ta7;
                    this.sms.service_provider_template_id = data.result.ta9;
                    this.sms.ta_id = data.result.ta1;
                    this.sms.sms_variables = data.result.ta10;
                    this.loading = false;
                } else {
                    this.loading = false;
                }
                this.loading = false;
            });
        },
        async onSelectProfile(e) {
            if (this.wp.header_file_type.value == 1) {
                this.allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
                this.file = e.files[0];
            } else if (this.wp.header_file_type.value == 2) {
                this.allowedExtensions = /(\.pdf)$/i;
                this.file = this.$refs.docuploadref.files[0];
            } else if (this.wp.header_file_type.value == 3) {
                this.allowedExtensions = /(\.mp4|\.mpeg)$/i;
                this.file = this.$refs.videouploadref.files[0];
            } else if (this.wp.header_file_type.value == 4) {
                this.allowedExtensions = /(\.mp3)$/i;
                this.file = this.$refs.tuneuploadeditref.files[0];
            }

            if (!this.file.size > 2048) {
                this.profileImageError = 'File size cannot exceed more than 2MB';
                this.wp.header_file_url = '';
                this.profileUrl = '';
            } else if (!this.file.name.match(this.allowedExtensions)) {
                this.profileImageError = 'Invalid file type';
                this.wp.header_file_url = '';
                this.profileUrl = '';
            } else {
                this.profileImageError = '';
                if (this.wp.header_file_type.value == 1) {
                    this.wp.header_file_url = e.files[0];
                    this.profileUrl = { url: this.file.objectURL, name: this.file.name };
                }
                else {
                    this.profileUrl = { name: this.file.name };
                    this.wp.header_file_url = this.file;
                }
            }
        },

        async onSelectProfileRCS(e) {
            if (this.rcs.header_file_type.value == 1) {
                this.allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
                this.file = e.files[0];
            } else if (this.rcs.header_file_type.value == 2) {
                this.allowedExtensions = /(\.pdf)$/i;
                this.file = this.$refs.docuploadref.files[0];
            } else if (this.rcs.header_file_type.value == 3) {
                this.allowedExtensions = /(\.mp4|\.mpeg)$/i;
                this.file = this.$refs.videouploadref.files[0];
            } else if (this.rcs.header_file_type.value == 4) {
                this.allowedExtensions = /(\.mp3)$/i;
                this.file = this.$refs.tuneuploadeditref.files[0];
            }

            if (!this.file.size > 2048) {
                this.profileImageErrorRCS = 'File size cannot exceed more than 2MB';
                this.rcs.header_file_url = '';
            } else if (!this.file.name.match(this.allowedExtensions)) {
                this.profileImageErrorRCS = 'Invalid file type';
                this.rcs.header_file_url = '';
            } else {
                this.profileImageErrorRCS = '';
                if (this.rcs.header_file_type.value == 1) {
                    this.rcs.header_file_url = e.files[0];
                    this.profileUrlRCS = { url: this.file.objectURL, name: this.file.name };
                }
                else {
                    this.profileUrlRCS = { name: this.file.name };
                }
            }
        },

        addUpdateSuperAdminTemplateEvents() {
            this.submitted = true;
            this.errormsg = '';
            this.v$.evt.$validate();

            if (!this.v$.evt.$error) {
                let fields = {};
                fields['tb1'] = this.routeParam;
                fields['tb2'] = this.localAdminActionId;
                fields['tb3'] = this.evt.eventName;
                fields['tb4'] = this.evt.ChannelName;
                fields['tb5'] = this.evt.evtStatusType;
                fields['ta52'] = this.evt.actiontaking.value;
                fields['ta1'] = this.setting_id;
                this.showLoader = true;
                this.ApiService.addUpdateSuperAdminTemplateEvents(fields).then((items) => {
                    if (items.status == 200) {
                        this.tabOpenStatus = this.evt.ChannelName;
                        // this.setting_id = items.ta_id;
                        this.showLoader = false;
                        var successMsg = items.message;
                        this.routeParam = items.tb_id;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                    } else {
                        this.showLoader = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },

        addWhatsAppTemplateSettings() {
            this.submitted = true;
            this.v$.wp.$validate();
            let formData = new FormData();
            formData.append('ta1', this.setting_id);
            formData.append('ta2', this.localAdminActionId);
            formData.append('ta3', this.routeParam); // event id
            formData.append('ta50', this.wp.wp_statustype);
            formData.append('ta18', this.wp.templateName);
            formData.append('ta19', this.wp.templateBody);
            if (this.wp.template_variables) {
                formData.append('ta20', this.wp.template_variables);
            } else {
                formData.append('ta20', '');
            }
            formData.append('ta21', this.wp.language.value);
            if (this.wp.next_template_name) {
                formData.append('ta22', this.wp.template_reply_type.value);
            }
            else {
                formData.append('ta22', '');
            }
            if (this.wp.next_template_name) {
                formData.append('ta23', this.wp.next_template_name.value);
            } else {
                formData.append('ta23', '');
            }
            formData.append('ta24', this.wp.first_button_name);
            if (this.wp.first_button_template_name !== '' && this.wp.first_button_template_name !== null) {
                formData.append('ta25', this.wp.first_button_template_name.value);
            } else {
                formData.append('ta25', '');
            }
            formData.append('ta26', this.wp.second_button_name);
            if (this.wp.second_button_template_name !== '' && this.wp.second_button_template_name !== null) {
                formData.append('ta27', this.wp.second_button_template_name.value);
            } else {
                formData.append('ta27', '');
            }
            formData.append('ta28', this.wp.third_button_name);
            if (this.wp.third_button_template_name !== '' && this.wp.third_button_template_name !== null) {
                formData.append('ta29', this.wp.third_button_template_name.value);
            }
            else {
                formData.append('ta29', '');
            }
            formData.append('ta30', this.wp.headerstatus);
            if (this.wp.headerstatus == 1) {
                if (this.wp.header_file_url != null) {
                    formData.append('ta31', this.wp.header_file_url);
                }
            }

            if (!this.v$.wp.$error) {
                this.showLoader = true;
                this.ApiService.addUpdateSuperAdminEventWhatsAppTemplateSettings(formData).then((items) => {
                    this.loading = this.showLoader = this.isDialogOpen = false;

                    if (items.status == 200) {
                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });


                    } else {
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                        this.showLoader = false;
                    }
                });
            }
        },

        addUpdateEventRCSTemplateSettings() {
            this.submitted = true;
            this.v$.rcs.$validate();
            let formData = new FormData();
            formData.append('ta1', this.setting_id);
            formData.append('ta2', this.localAdminActionId);
            formData.append('ta3', this.routeParam); // event id
            formData.append('ta52', this.rcs.rcs_statustype);
            formData.append('ta33', this.rcs.templateName);
            formData.append('ta34', this.rcs.templateBody);
            if (this.rcs.template_variables) {
                formData.append('ta35', this.rcs.template_variables);
            } else {
                formData.append('ta35', '');
            }
            formData.append('ta36', this.rcs.language.value);
            if (this.rcs.next_template_name) {
                formData.append('ta37', this.rcs.template_reply_type.value);
            }
            else {
                formData.append('ta37', '');
            }
            if (this.rcs.next_template_name) {
                formData.append('ta38', this.rcs.next_template_name.value);
            } else {
                formData.append('ta38', '');
            }
            formData.append('ta39', this.rcs.first_button_name);
            if (this.rcs.first_button_template_name !== '' && this.rcs.first_button_template_name !== null) {
                formData.append('ta40', this.rcs.first_button_template_name.value);
            } else {
                formData.append('ta40', '');
            }
            formData.append('ta41', this.rcs.second_button_name);
            if (this.rcs.second_button_template_name !== '' && this.rcs.second_button_template_name !== null) {
                formData.append('ta42', this.rcs.second_button_template_name.value);
            } else {
                formData.append('ta42', '');
            }
            formData.append('ta43', this.rcs.third_button_name);
            if (this.rcs.third_button_template_name !== '' && this.rcs.third_button_template_name !== null) {
                formData.append('ta44', this.rcs.third_button_template_name.value);
            }
            else {
                formData.append('ta44', '');
            }
            formData.append('ta47', this.rcs.headerstatus);
            if (this.rcs.headerstatus == 1) {
                if (this.rcs.header_file_url != null) {
                    formData.append('ta48', this.rcs.header_file_url);
                }
            }

            if (!this.v$.rcs.$error) {
                this.showLoader = true;
                this.ApiService.addUpdateSuperAdminEventRCSTemplateSettings(formData).then((items) => {
                    this.loading = this.showLoader = this.isDialogOpen = false;

                    if (items.status == 200) {
                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });


                    } else {
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                        this.showLoader = false;
                    }
                });
            }
        },

        addUpdateEventEmailTemplateSettings() {
            this.submitted = true;
            this.v$.em.$validate();
            let formData = new FormData();
            formData.append('ta1', this.setting_id);
            formData.append('ta2', this.localAdminActionId);
            formData.append('ta3', this.routeParam); // event id
            formData.append('ta14', this.em.email_statustype);
            formData.append('ta15', this.em.email_subject);
            formData.append('ta16', this.em.email_body);
            if (this.em.email_variables)
                formData.append('ta17', this.em.email_variables);
            else
                formData.append('ta17', '');
            if (!this.v$.em.$error) {
                this.showLoader = true;
                this.ApiService.addUpdateSuperAdminEventEmailTemplateSettings(formData).then((items) => {
                    this.loading = this.showLoader = this.isDialogOpen = false;

                    if (items.status == 200) {
                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                    } else {
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                        this.showLoader = false;
                    }
                });
            }
        },

        addUpdateAdminEventSMSTemplateSettings() {
            this.submitted = true;
            this.v$.sms.$validate();
            let formData = new FormData();
            formData.append('ta1', this.setting_id);
            formData.append('ta2', this.localAdminActionId);
            formData.append('ta3', this.routeParam); // event id
            formData.append('ta4', this.sms.sms_statustype);
            formData.append('ta5', this.sms.sms_header_name);
            formData.append('ta6', this.sms.template_id);
            formData.append('ta7', this.sms.sms_body);
            formData.append('ta9', this.sms.service_provider_template_id);
            if (this.sms.sms_variables) {
                formData.append('ta10', this.sms.sms_variables);
            } else {
                formData.append('ta10', '');
            }
            if (!this.v$.sms.$error) {
                this.showLoader = true;
                this.ApiService.addUpdateSuperAdminEventSMSTemplateSettings(formData).then((items) => {
                    this.loading = this.showLoader = this.isDialogOpen = false;

                    if (items.status == 200) {
                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                    } else {
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                        this.showLoader = false;
                    }
                });
            }
        },

        getHeaderFileTypeFromURL(url) {
            const pathname = new URL(url).pathname;
            const fileName = pathname.split("/").pop();
            const fileExtension = fileName.split(".").pop();
            const extensionMapping = {
                'jpg': 1,
                'jpeg': 1,
                'png': 1,
                'pdf': 2,
                'doc': 2,
                'docx': 2,
                'mp4': 3,
                'mp3': 4
            };

            return extensionMapping[fileExtension.toLowerCase()] || 'unknown';
        },

        goToPrevious() {
            router.push({
                name: "listSuperAdminTemplateEvent",
            });
        },

        updatePreview() {
            this.previewHtml = this.em.email_body;
            this.previewHtmlStatus = !this.previewHtmlStatus;
        }
    },
};
</script>

<style scoped></style>
